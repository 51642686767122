export const pagesPath = {
  "accounts": {
    $url: (url?: { hash?: string }) => ({ pathname: '/accounts' as const, hash: url?.hash })
  },
  "company": {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/company/[id]' as const, query: { id }, hash: url?.hash })
    })
  },
  "login": {
    $url: (url?: { hash?: string }) => ({ pathname: '/login' as const, hash: url?.hash })
  },
  "message_history": {
    $url: (url?: { hash?: string }) => ({ pathname: '/message-history' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
}

export type PagesPath = typeof pagesPath
