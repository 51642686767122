import { Stack, Box, Skeleton } from '@chakra-ui/react'
import { FC } from 'react'

type SkeletonGlobalNavigationProps = Record<string, never>

export const SkeletonGlobalNavigation: FC<SkeletonGlobalNavigationProps> = () => (
  <Stack direction={'column'} h="100%" bg="gray.200" p={8} spacing={'8'}>
    <Box>
      <Skeleton height={'2.2rem'} />
    </Box>

    <Stack flex={'1 1 auto'} direction="column" h="100%">
      <Stack direction="column" gap={4}>
        <Skeleton height={'1rem'} />
        <Skeleton height={'1rem'} />
      </Stack>
    </Stack>

    <Stack direction="column" gap={4}>
      <Skeleton height={'1rem'} />
      <Skeleton height={'1rem'} />
    </Stack>
  </Stack>
)
