// Code generated by graphql-codegen. DO NOT EDIT.
import * as Types from '../../../generated/types'

import gql from 'graphql-tag'
import * as Urql from 'urql'
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type GetManagerQueryVariables = Types.Exact<{ [key: string]: never }>

export type GetManagerQuery = {
  readonly __typename?: 'Query'
  readonly meByManager:
    | {
        readonly __typename?: 'Manager'
        readonly id: string
        readonly name: string
        readonly companyID: string
      }
    | null
    | undefined
}

export const GetManagerDocument = gql`
  query getManager {
    meByManager {
      id
      name
      companyID
    }
  }
`

export function useGetManagerQuery(
  options?: Omit<Urql.UseQueryArgs<GetManagerQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetManagerQuery, GetManagerQueryVariables>({
    query: GetManagerDocument,
    ...options,
  })
}
