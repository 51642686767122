import { Stack, Link as ChakraLink, Heading, Box } from '@chakra-ui/react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { FC, ReactNode } from 'react'

import { pagesPath } from '@/libs/pathpida/$path'

import { getEnv } from '@/utils/get-env'

import { CompanyButton } from '../company-button/company-button'

export const GlobalNavigation: FC = () => {
  const { pathname: currentPath } = useRouter()

  return <GlobalNavigationView currentPath={currentPath} CompanyButton={<CompanyButton />} />
}

type Props = {
  currentPath: string
  CompanyButton: JSX.Element // このボタンに企業情報やログアウトなどの機能が集中しているため、コンポーネント分割して外から渡す設計にしている
}
export const GlobalNavigationView: FC<Props> = ({ currentPath, CompanyButton }) => {
  return (
    <Stack direction={'column'} h="100%" bg="gray.200" p={8} spacing={'8'}>
      <Box>
        <Heading size="md" whiteSpace="pre-wrap">{`AGAROOT ACADEMY \nfor Biz`}</Heading>
      </Box>

      <Stack flex={'1 1 auto'} direction="column" h="100%">
        <Stack direction="column" gap={4}>
          <GlobalNavigationLink
            destination={pagesPath.accounts.$url().pathname}
            current={currentPath}
          >
            受講生
          </GlobalNavigationLink>

          {/* <GlobalNavigationLink
            destination={pagesPath.message_history.$url().pathname}
            current={currentPath}
          >
            メッセージ送信履歴
          </GlobalNavigationLink> */}
        </Stack>
      </Stack>

      <Stack direction="column" gap={4}>
        {CompanyButton}
        <ChakraLink href={getEnv().NEXT_PUBLIC_CONTACT_URL} isExternal>
          お問い合わせ
        </ChakraLink>
      </Stack>
    </Stack>
  )
}

type GlobalNavigationLinkProps = {
  destination: string
  current: string
  children: ReactNode
}
const GlobalNavigationLink: FC<GlobalNavigationLinkProps> = ({
  destination,
  current,
  children,
}) => (
  <NextLink href={destination} passHref>
    <ChakraLink fontWeight={destination === current ? 'bold' : 'normal'}>{children}</ChakraLink>
  </NextLink>
)
