import { useAuth0 } from '@auth0/auth0-react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Link as ChakraLink, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { FC, MouseEventHandler } from 'react'

import { pagesPath } from '@/libs/pathpida/$path'

import { Loading } from '@/ui/loading/loading'

import { useSessionContext } from '@/features/auth/session-context/session-context'

import { useGetCompanyQuery } from './index.generated'

export const CompanyButton: FC = () => {
  const { pathname: currentPath, push } = useRouter()
  const { manager } = useSessionContext()
  const [getCompanyQuery, _refetch] = useGetCompanyQuery({ variables: { id: manager.companyId } })
  const { logout } = useAuth0()

  if (getCompanyQuery.fetching) {
    return <Loading />
  }

  const maybeCompany = getCompanyQuery.data?.company

  const company = maybeCompany ?? { id: null, name: '-' }

  const onClickCompanyDetailMenu: CompanyButtonViewProps['onClickCompanyDetailMenu'] = (_) => {
    if (company.id === null) {
      return
    }
    push(pagesPath.company._id(company.id).$url())
  }
  const onClickLogoutMenu: CompanyButtonViewProps['onClickLogoutMenu'] = (_) => {
    logout({
      returnTo: `${window.location.origin}${pagesPath.login.$url().pathname}`,
    })
  }

  return (
    <CompanyButtonView
      company={company}
      currentPath={currentPath}
      onClickCompanyDetailMenu={onClickCompanyDetailMenu}
      onClickLogoutMenu={onClickLogoutMenu}
    />
  )
}

type CompanyButtonViewProps = {
  company: { id: string | null; name: string }
  currentPath: string
  onClickCompanyDetailMenu: OnClickButton
  onClickLogoutMenu: OnClickButton
}
type OnClickButton = MouseEventHandler<HTMLButtonElement>
export const CompanyButtonView: FC<CompanyButtonViewProps> = (props) => {
  const isInCompanyDetailPage =
    props.company.id !== null &&
    props.currentPath === pagesPath.company._id(props.company.id).$url().pathname

  return (
    <Menu>
      <MenuButton fontWeight={isInCompanyDetailPage ? 'bold' : 'normal'} as={ChakraLink}>
        {props.company.name}
        <ChevronRightIcon />
      </MenuButton>
      <MenuList>
        {props.company.id !== null && (
          <MenuItem onClick={props.onClickCompanyDetailMenu}>企業情報</MenuItem>
        )}
        <MenuItem onClick={props.onClickLogoutMenu}>ログアウト</MenuItem>
      </MenuList>
    </Menu>
  )
}
