import { useAuth0 } from '@auth0/auth0-react'
import { createContext, FC, ReactNode, useContext } from 'react'

import { pagesPath } from '@/libs/pathpida/$path'

import { Loading } from '@/ui/loading/loading'

import { useGetManagerQuery } from './index.generated'

type Session = {
  manager: Manager
}
type Manager = {
  id: string
  name: string
  companyId: string
}

export const useSessionContext = (): Session => {
  const session = useContext(SessionContext)

  if (session === null) {
    // TODO: https://github.com/Agaroot-Dev/agaroot_academy_for_biz/issues/194
    throw new Error('`SessionContextProvider`の外側で`SessionContext`が使用されています')
  }

  return session
}

type Props = {
  children: ReactNode
  loading?: JSX.Element
}
export const SessionContextProvider: FC<Props> = ({
  children,
  loading = <Loading description={{ type: 'loadingAuth' }} />,
}) => {
  const [getManagerQuery, _refetch] = useGetManagerQuery()
  const { logout } = useAuth0()

  if (getManagerQuery.fetching) {
    return loading
  }

  const maybeManager = getManagerQuery.data?.meByManager

  if (!maybeManager) {
    logout({
      returnTo: `${window.location.origin}${pagesPath.login.$url().pathname}`,
    })

    return null
  }

  const session: Session = {
    manager: {
      id: maybeManager.id,
      name: maybeManager.name,
      companyId: maybeManager.companyID,
    },
  }
  return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>
}

const SessionContext = createContext<Session | null>(null)
